.relics__overlay {
  position: fixed;

  top: 0;
  left: 0;
  width: 100vw;
  height: var(--dvh);

  background: #19212Ae6;

  z-index: 199995;
}

.relics__wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;

  width: 100vw;
  height: auto;

  color: #fff;
  background: linear-gradient(150.14deg, #324664 5.36%, #243247 94.98%), url("../images/relics-bg-fs8.png");
  background-size: cover;
  background-blend-mode: lighten;
  border-radius: 24px 24px 0 0;
  /* box-shadow: 0 -20px 60px #3686FF; */
  font-family: 'Open Sans';

  z-index: 200000;

  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.relics__wrapper svg {
  fill: #fff;
}

.relics__wrapper strong {
  color: #FFE500;
}

.relics__wrapper .relics__description strong {
  font-weight: 600;
}

.relics__wrapper strong svg {
  fill: #FFE500;
}

.relics__wrapper .relics__box {
  position: relative;
  height: 40vw;
  width: 50vw;
  margin: 0 auto;
}

.relics__wrapper .relics__box img {
  height: 40vw;
  position: absolute;
  top: 4vw;
  left: 10px;
  mix-blend-mode: color-dodge;
}

.relics__wrapper .relics__box .bg {
  position: absolute;
  width: 32vw;
  height: 30vw;
  left: calc(50% - 16vw);
  top: 2vw;

  background: linear-gradient(90deg, #FFDA15 35.17%, #FF7A00 92.45%);
  opacity: 0.5;
  filter: blur(27.1px);
  border-radius: 8px;
}

.relics__wrapper .relics__title {
  font-size: 2.25rem;
  width: 100%;
  text-align: center;
  font-family: 'Oswald';
  font-weight: 550;
}

.relics__description {
  font-size: 1.25rem;
  line-height: 1.67rem;
  padding: 0.83rem 1rem;
  font-weight: 550;
  font-family: 'Open Sans Normal';
  text-align: center;
}

.relics__buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  width: 100%;
}

.relics__buttons2 .button__wrapper {
  position: relative;

  &::before {
    content: ' ';
    background-image: linear-gradient(273.08deg, #FFDA15 0%, #DB00FF 98.88%);
    border-radius: 30px;
    position: absolute;
    top: -0.3rem;
    left: -5%;
    height: 5rem;
    width: 110%;
    filter: blur(22px);
    z-index: -1;
  }
}

.relics__buttons2 button {
  filter: none;
  font-size: 1.5rem;
  font-family: 'Oswald';
  font-weight: 550;
  text-transform: uppercase;
  padding: 15px 40px;
  background: linear-gradient(90deg, #0094FF 0%, #DB00FF 100%), url("../images/relic-button-bg.png");
  background-blend-mode: overlay;
  background-size: cover;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  color: white !important;
  display: inline-block;
  z-index: 200100;
}

.relics__buttons button {
  background: linear-gradient(92.31deg, #FFD600 5.6%, #C58D1A 49.54%, #744F00 96.56%), url("../images/relic-button-bg.png");
  background-blend-mode: overlay;
  background-size: cover;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  color: white !important;
  display: inline-block;
  z-index: 200100;
  filter: none;
  font-size: 1.5rem;
  font-family: 'Oswald';
  font-weight: 550;
  text-transform: uppercase;
  padding: 15px 40px;
  box-shadow: 0 5px 15px #FFFC3880;
  text-shadow: 0 0 4px #33333380;
}

.relics__buttons button svg {
  fill: white !important;
}

.relics__buttons button.open:hover {
  box-shadow: 0 5px 25px #FFFC3880;
  transition: box-shadow .6s;
}

.relics__buttons button.open.disabled {
  border: 2px solid #999;
  background: linear-gradient(92.31deg, #b0a569 5.6%, #865f12 49.54%, #4b3403 96.56%), url("../images/relic-button-bg.png");
  background-blend-mode: darken;
  background-size: cover;
  box-shadow: none;
  cursor: not-allowed;
}

.relics__reject {
  font-family: 'Open Sans Normal';
  margin-top: 1rem;
  text-align: center;
  width: 100%;
  color: #fff;
}

.relics__reject a {
  font-family: 'Open Sans Normal';
  color: #fff;
  text-decoration: none;
  border-bottom: 1px dotted #fff;
}

