.quest-body {
  background: linear-gradient(0deg, var(--tgm-theme-bg-color) 0%, rgba(79,81,115,0) 14%), url(../images/church_bg3.jpg) no-repeat, var(--tgm-theme-secondary-bg-color);
      background-position: bottom 60px center;
}


.nemesis {
  position: absolute;
  top: calc(var(--dvh) - 340px);
  left: calc(50vw - 85px);
  animation: nemesis-swinging 6s ease-in-out 0s infinite;
}

.nemesis img {
  width: 170px;
}

@keyframes nemesis-popping {
  from {transform: translateY(0px); }
  3% {transform: translateY(-20px)  scaleY(0.98); }
  5% {transform: translateY(0px) scaleY(1); }
  7% {transform: translateY(-10px) scaleY(0.98); }
  9% {transform: translateY(0px) scaleY(1); }
  to {transform: translateY(0px); }
}

@keyframes nemesis-swinging {
  from {left: calc(50vw - 70px); transform: rotate(0deg)}
  25% {left: calc(50vw - 90px); transform: rotate(-3deg)}
  50% {transform: rotate(0deg)}
  75% {left: calc(50vw - 50px); transform: rotate(3deg)}
  to {left: calc(50vw - 70px); transform: rotate(0deg)}
}

@keyframes nemesis-popping-and-swinging {
  from {transform: translateY(0px); }
  3% {transform: translateY(-20px)  scaleY(0.98); }
  5% {transform: translateY(0px) scaleY(1) rotate(-3deg); }
  7% {transform: translateY(-10px) scaleY(0.98) rotate(0deg); }
  9% {transform: translateY(0px) scaleY(1) rotate(3deg); }
  13% {transform: rotate(0deg);}
  to {transform: translateY(0px); }
}

@keyframes progress-quest {
  from {width: 0%}
  to {width: 100%}
}

.damage2 {
  font-size: 300%;
  color: #fff;
  text-shadow: 0 0 5px #222;
  opacity: 0;
  position: absolute;
  left: 10vw;
  z-index: 10000;
  top: calc(var(--dvh) - 160px);
}

.damage2.path1, .damage2.path2, .damage2.path3, .damage2.path4 {
  animation: damage2-flight 1s ease-out;
}

@keyframes damage2-flight {
  from {top: calc(var(--dvh) - 160px); font-size: 50%; opacity: 1;}
  to {top: calc(var(--dvh) - 300px); font-size: 250%; opacity: 0; }
}

.quest__hp.active {
  margin-left: 10px;
  animation: hp-shivering .25s;
  animation-iteration-count: 3;
}

.nemesis.beaten {
  animation: shivering .25s;
  animation-iteration-count: 3;
}

@keyframes hp-shivering {
  from {margin-left: 10px}
  25% {margin-left: 9px}
  50% {margin-left: 11px}
  75% {margin-left: 9px}
  to {margin-left: 10px}
}

@keyframes shivering {
  from {transform: rotate(0deg)}
  25% {transform: rotate(2deg)}
  50% {transform: rotate(-2deg)}
  75% {transform: rotate(2deg)}
  to {transform: rotate(0deg)}
}

.damage {
  font-size: 300%;
  color: #fff;
  text-shadow: 0 0 5px #222;
  opacity: 0;
  position: absolute;
  left: 47vw;
  z-index: 10000;
  top: calc(var(--dvh) - 470px);
}

.damage.path1 {
  animation: damage-flight1 .75s ease-out;
}

.damage.path2 {
  animation: damage-flight2 .75s ease-out;
}

.damage.path3 {
  animation: damage-flight3 .75s ease-out;
}

.damage.path4 {
  animation: damage-flight4 .75s ease-out;
}

@keyframes damage-flight1 {
  from {top: calc(var(--dvh) - 330px); font-size: 50%; opacity: 1;}
  75% { opacity: 0.6; left: 40vw}
  to {top: calc(var(--dvh) - 470px); left: 40vw; font-size: 300%; opacity: 0; }
}

@keyframes damage-flight2 {
  from {top: calc(var(--dvh) - 330px); font-size: 50%; opacity: 1;}
  75% { opacity: 0.6; left: 60vw}
  to {top: calc(var(--dvh) - 470px); left: 60vw; font-size: 300%; opacity: 0; }
}

@keyframes damage-flight3 {
  from {top: calc(var(--dvh) - 330px); font-size: 50%; opacity: 1;}
  75% { opacity: 0.6; left: 50vw}
  to {top: calc(var(--dvh) - 470px); left: 50vw; font-size: 300%; opacity: 0; }
}

@keyframes damage-flight4 {
  from {top: calc(var(--dvh) - 330px); font-size: 50%; opacity: 1;}
  75% { opacity: 0.6}
  to {top: calc(var(--dvh) - 470px); font-size: 300%; opacity: 0; }
}

.ton-waves-quest {
  background: url("../images/ton-overlay-quest.png") no-repeat;
  mix-blend-mode: hard-light;
  position: absolute;
  width: 130px;
  height: 140px;
  top: calc(var(--dvh) - 557px);
  left: calc(50vw - 65px);
  animation: waves-ton 3s linear 0s infinite;
}