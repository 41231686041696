.faith-received {
    position: absolute;
    z-index: 15000;
    top: 120px;
    width: 100vw;
}

.reward-animation {
  width: 220px;
  margin: 0 auto;
}

.faith-note {
  font-family: 'Oswald';
  position: absolute;
  width: 140px;
  margin-left: calc(50vw - 75px);
  top: 100px;
  height: 2rem;
  /* background-color: white;
  border: 1px solid white; */
  border-radius: 10px;
  text-align: center;
  font-size: 2.5rem;
  line-height: 1rem;
  font-weight: bold;
  color: rgb(5, 27, 84);
  text-shadow: 0 0 15px goldenrod;
}