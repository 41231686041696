.onboarding--base {
  color: white;
  height: var(--dvh);
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  z-index: 300000;
  background-color: #030201;
}

.onboarding {
  background: linear-gradient(to bottom, #1e2b4a, #3a4b6b);
  background-image: url(../../images/onboarding/background.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: var(--dvh);
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
}

.onboarding--hell {
  background: url('../../images/onboarding/hell-bg.jpg') no-repeat, #00000080;
  background-size: cover;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center center;
  height: var(--dvh);
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  }


.onboarding__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.onboarding__lister {
  display: flex;
  position: absolute;
  width: 100vw;
  top: 1.5rem;
  bottom: 6rem;
  left: 0;
  z-index: 50;
}

.onboarding__lister__pane {
  width: 50%;
  box-sizing: border-box;
  height: 100%;
}

.faith-increase {
    display: flex;
    width: 100%;
    height: 70%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.faith-increase__rays {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../images/onboarding/rays.svg) no-repeat center center;
}

.faith-increase__circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: url(../../images/onboarding/ellipse.svg) no-repeat center center;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.faith-increase__value {
    font-size: 48px;
    font-weight: bold;
    z-index: 3;
    display: block;
    position: absolute;
    z-index: 5;

}

.faith-growth {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: auto;
    z-index: 10;
}

.faith-growth__title {
    font-size: 2.25rem;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 3.75rem !important;
    font-weight: 500;
    font-family: 'Oswald';
}

.faith-growth__title svg {
  fill: white;
  height: 2.1rem;
}

.faith-growth__description {
    font-size: 1.15rem;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 500;
    font-family: 'Open Sans';
}

.faith-growth__description svg {
  fill: white;
  height: 1rem;
}

.onboarding__next-button {
  background: linear-gradient(92.31deg, #FFD600 5.6%, #C58D1A 49.54%, #744F00 96.56%), url("../../images/relic-button-bg.png");
  background-blend-mode: overlay;
  background-size: cover;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  color: white !important;
  display: inline-block;
  filter: none;
  width: 90%;
  z-index: 50;
  font-size: 1.3rem;
  font-family: 'Oswald';
  font-weight: 550;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  padding: 15px 40px;
  box-shadow: 0 5px 15px #FFFC3880;
  text-shadow: 0 0 4px #33333380;
}

.onboarding__slide-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 1rem .25rem 0;
  }

  .onboarding__slide-indicator-item {
    width: 30%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
  }

  .onboarding__slide-indicator-item--active {
    background-color: white;
  }

  .pop1 {
    background: url('../../images/onboarding/pop1.png') no-repeat left center;
    background-size: contain;
    position: absolute;
    bottom: 14rem;
    left: 0;
    width: 75vw;
    height: 87vw;
    animation: pop1-enter .75s linear;
  }

  .pop2 {
    background: url('../../images/onboarding/pop2.png') no-repeat right center;
    background-size: contain;
    position: absolute;
    bottom: 14rem;
    right: 0;
    width: 75vw;
    height: 87vw;
    animation: pop2-enter .75s linear;
  }

  @keyframes pop1-enter {
    from {left: -75vw}
    to {left: 0}
  }

  @keyframes pop2-enter {
    from {right: -75vw}
    to {right: 0}
  }

  .saton {
    background: url('../../images/onboarding/soton.png') no-repeat left center;
    background-size: contain;
    position: absolute;
    bottom: 15rem;
    left: 0;
    width: 100vw;
    height: 90vw;
    animation: saton-enter .75s linear;
  }

  .pasta {
    background: url('../../images/onboarding/pasta.png') no-repeat right center;
    background-size: contain;
    position: absolute;
    bottom: 14rem;
    right: 0;
    width: 100vw;
    height: 80vw;
    animation: pasta-enter .75s linear;
  }

  .sunduk {
    bottom: 11rem;
    position: absolute;
    left: 0;
    width: 100vw;
    height: 56vw;
    background: url('../../images/onboarding/sunduk.png') no-repeat center center;
    background-size: contain;
    animation: sunduk-enter .75s linear;
  }

  @keyframes saton-enter {
    from {left: -100vw}
    to {left: 0}
  }

  @keyframes pasta-enter {
    from {right: -100vw}
    to {right: 0}
  }

  @keyframes sunduk-enter {
    from {bottom: 150vh}
    to {bottom: 11rem}
  }

  .chest {
    bottom: 14rem;
    position: absolute;
    left: auto;
    width: 270px;
    height: 270px;
    right: auto;
    background: url('../../images/onboarding/chest.png') no-repeat center center;
    background-size: contain;
    z-index: 10;
  }

.chest-coins {
    bottom: 12rem;
    position: absolute;
    left: auto;
    width: 100vw;
    height: 70vh;
    right: auto;
    background: url('../../images/onboarding/coins.png') no-repeat center center;
    background-size: contain;
    z-index: 5;
  }

  .popes-bg-shadow {
    box-shadow: 0 0 20px 20px #030201;
  }

  .friends-bg {
    position: absolute;
    height: 15rem;
    bottom: 0;
    width: 100vw;
    background-color: #030201;
    z-index: 5;
  }

  .friends-center {
    bottom: 14rem;
    position: absolute;
    left: auto;
    width: 100vw;
    z-index: 10;
    height: 70vw;
    right: auto;
    background: url('../../images/onboarding/friends-center.png') no-repeat center center;
    background-size: cover;
  }

  .onboarding__transitions svg {
    fill: #FFE500;
  }