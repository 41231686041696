/* {"accent_text_color":"#6ab2f2","bg_color":"#17212b","button_color":"#5288c1",
  "button_text_color":"#ffffff","destructive_text_color":"#ec3942","header_bg_color":"#17212b",
  "hint_color":"#708499","link_color":"#6ab3f3","secondary_bg_color":"#232e3c",
  "section_bg_color":"#17212b","section_header_text_color":"#6ab3f3",
  "subtitle_text_color":"#708499","text_color":"#f5f5f5"} */

:root {
  --tgm-theme-bg-color: #17212b;
  --tgm-theme-text-color: #f5f5f5;
  --tgm-theme-hint-color: #708499;
  --tgm-theme-link-color: #6ab3f3;
  --tgm-theme-button-color: #5288c1;
  --tgm-theme-button-text-color: #ffffff;
  --tgm-theme-secondary-bg-color: #232e3c;
  --tgm-theme-text-color-semi: #6ab3f380;
  --tgm-theme-text-color-quart: #f5f5f540;

  --star-color-A: white;
  --star-color-B: transparent;

  --default-font: 'Open Sans', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Mplus1m';
  src: local('Mplus1m'), url(./fonts/mplus-1m-medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./fonts/OpenSans-VariableFont_wdth,wght.ttf) format('truetype');
  /* font-weight: 300; */
  font-style: normal;
  font-stretch: semi-condensed;
}

@font-face {
  font-family: 'Open Sans Normal';
  src: local('Open Sans'), url(./fonts/OpenSans-VariableFont_wdth,wght.ttf) format('truetype');
  /* font-weight: 300; */
  font-style: normal;
  font-stretch: normal;
}

/* @font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./fonts/OpenSans-VariableFont_wdth,wght.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
} */

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--tgm-theme-hint-color) #232e3c80;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #232e3c80;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--tgm-theme-hint-color);
  border-radius: 6px;
  border: 1px solid #232e3c80;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 500;
}

html {
    margin: 0;
    padding: 0;
    height: var(--dvh,100dvh);
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    /* overflow: hidden; */
    /* box-sizing: border-box;
          border: 1px solid green; */
}

body {
  font-family: var(--default-font);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.pray-body {
    background: linear-gradient(0deg, var(--tgm-theme-bg-color) 0%, rgba(79,81,115,0) 14%), url(./images/church_bg3.jpg) no-repeat, var(--tgm-theme-secondary-bg-color);
    background-position: bottom 60px center;
    background-color: var(--tgm-theme-bg-color);
}

.pray2-body {
    background: linear-gradient(0deg, var(--tgm-theme-bg-color) 0%, rgba(79,81,115,0) 14%), url(./images/church_bg3.jpg) no-repeat, var(--tgm-theme-secondary-bg-color);
    background-position: bottom 60px center;
    background-color: var(--tgm-theme-bg-color);
}

#root {
  height: var(--dvh,100dvh);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.bg-animation {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 0px;
  right: 0px;
  z-index: -1000;
}

.coins-animation {
  position: absolute;
  top: 0;
  bottom: 70px;
  left: 0px;
  right: 0px;
  z-index: 199996;
}

.waves-animation {
  position: absolute;
  top: calc(var(--dvh) - 430px);
  left: calc(50vw - 84px);
  width: 80px;
  z-index: -5000;
}

.faith-animation {
  position: absolute;
  top: -40px;
  right: 0;
}

.fire-anim {
  bottom: 70px;
  position: absolute;
  z-index: -5000;
  width: 100vw;
}

.contentWrapper {
  background: var(--tgm-theme-bg-color);
  color: var(--tgm-theme-text-color) !important;
  border-radius: 10px;
  margin: 10px 10px 0 10px;
  padding: 20px;
  box-sizing: border-box;
}

.contentWrapper.wallet {
  /* background: #ffffffaa;
  color: var(--tgm-theme-text-color) !important; */
  height: 60px;
  min-height: 60px;
  flex: 0 0 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 15px rgba(255,255,255,0.3);
}

.contentWrapper.attract {
  border: 1px solid var(--tgm-theme-secondary-bg-color);
  background: linear-gradient(45deg, rgb(183, 242, 237, 0.6) 0%, rgb(46, 151, 181, 0.3) 100%) var(--tgm-theme-bg-color);
  background-size: 400% 400%;
  cursor: pointer;
  z-index: 1000;
  animation: shining_wallet 4s infinite alternate;
}
@keyframes shining_wallet {
  from {box-shadow: rgba(3, 222, 251, 0.5) 0 0 20px ; background-position:0% 99%}
  40% {box-shadow: rgba(3, 222, 251, 0.5) 0 0 5px ; background-position:100% 2% }
  to {box-shadow: rgb(3, 222, 251, 0.5) 0 0 35px ; background-position:0% 99% }
}


main {
  /* border: 1px transparent solid; */
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
  flex: 1 0 auto;
}

footer {
  font-family: 'Oswald';
  width: 100vw;
  box-sizing: border-box;
  height: 60px;
  /* overflow: hidden; */


  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: bottom;
  flex-direction: row;

  background: radial-gradient(#40404033 20%, transparent 0) var(--tgm-theme-bg-color);
  background-size: 3px 3px;

  margin: 0;
  margin-top: auto;
  padding: 0;

  flex: 0 0 0;
}


.taskbar .item {
  width: 110px;
  height: 60px;
  text-align: center;
  /* padding: 10px 0 0 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: end;
  row-gap: 0px;
  column-gap: 0px;
  text-transform: uppercase;
  color: var(--tgm-theme-text-color) !important;
  opacity: 0.6;
  transition: .6s;
  cursor: pointer;
  font-size: 82.5%;
  line-height: 1.1rem;
  position: relative;
}

.main-btn {
  font-family: 'Oswald';
  border: 1px solid #33333348;
  box-shadow: 0 0 5px #223e50da;
  border-radius: 5px;
  /* color: var(--tgm-theme-text-color) !important; */
  color: #333;
  font-size: 20px;
  font-family: 'Oswald';
  /* background: #1f4c68da; */
  background: #ffffffaa;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  width: calc(100vw - 20px);
  box-sizing: border-box;
  position: relative;
  bottom: 10px;
  margin: 0 10px;
  height: 60px;
  transition: background .6s;
  z-index: 75000;
}
.main-btn.pray {
    animation: shining_pray 2s infinite alternate;
}
@keyframes shining_pray {
  from {box-shadow: rgba(3, 222, 251, 1) 0 0 20px }
  50% {box-shadow: rgba(3, 222, 251, 1) 0 0 5px }
  to {box-shadow: rgb(3, 222, 251, 1) 0 0 35px }
}

.main-btn:hover {
  background: #fffffff0;
  /* background: #1f4c68ed; */
  text-decoration: none;
  transition: background .6s;
}

.main-btn.disabled {
  cursor: default !important;
}

.main-btn.disabled:active, .main-btn.disabled:hover {
  background: #ffffffaa !important;
  box-shadow: 0 0 5px #223e50da !important;
}

.main-btn:active {
  background: #223e50f0;
  box-shadow: 0 0 5px #223e5030;
  text-decoration: none;
}

.main-btn .btn {
	position: relative;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	line-height: 56px;
	text-align: center;
	z-index: 10;
	opacity: 1;
}

.main-btn .btn svg {
  fill: transparent;
  stroke: #333;
}

.main-btn.active .btn svg {
  stroke: #555;
}

.main-btn .btn2 {
	position: absolute;
  left: 0;
	top: 21px;
	right: 0;
	bottom: 0;
  font-size: 60%;
	text-align: right;
  padding-right: 10px;
	z-index: 10;
	opacity: 1;
}

.main-btn.active {
  color: #555 !important;
}

.main-btn.receive {
  border-color: goldenrod;
  animation: shining 3.5s infinite alternate;
}
@keyframes shining {
  from {box-shadow: goldenrod 0 0 20px }
  40% {box-shadow: goldenrod 0 0 5px }
  to {box-shadow: goldenrod 0 0 35px }
}

.main-btn.receive .btn {
  text-shadow: 0 0 4px #33333350;
}

.claim-invites-btn {
  font-family: 'Oswald';
  border: 1px solid #33333348;
  box-shadow: 0 0 5px #223e50da;
  border-radius: 5px;
  /* color: var(--tgm-theme-text-color) !important; */
  color: #333;
  font-size: 16px;
  /* background: #1f4c68da; */
  background: #ffffffaa;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0.7rem auto 0.3rem;
  width: 200px;
  padding: 0.1rem 0;
  z-index: 75000;
  flex: 0 1 auto;
  height: 30px;
  position: inherit;
}

.claim-invites-btn:active {
  background: #e5f2fbf0;
  box-shadow: 0 0 5px #223e5030;
  text-decoration: none;
}

.wrapper-invites svg {
  fill: var(--tgm-theme-text-color);
}

.claim-invites-btn svg {
  fill: #333;
}

@keyframes shining-invites {
  from {box-shadow: #409540ee 0 0 8px }
  40% {box-shadow: #409540ee 0 0 3px }
  to {box-shadow: #409540ee 0 0 15px }
}

.relic-box {
  position: absolute;
	top: calc(var(--dvh) - 205px);
  left: calc(50vw + 30px);
  width: 92px;
	height: 80px;
  z-index: 50000;
  cursor: pointer;
  animation: box_entrance 4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

@keyframes box_entrance {
  0% {transform: translateY(-1200px); }
        22%   { transform: translateY(0); }
        25%  { transform: translateY(-18px); }
        28%  { transform: translateY(0); }
        30%  { transform:  translateY(-7px); }
        33%  { transform:     translateY(0); }
        100% { transform:    translateY(0); }
}

.relic-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.taskbar svg {
  fill: transparent;
  stroke: var(--tgm-theme-text-color);
  filter: none;
}

.taskbar .item.active {
  opacity: 1;
  text-shadow: 0 0 10px var(--tgm-theme-text-color-semi), 0 0 3px var(--tgm-theme-text-color-semi);
}

.taskbar .active svg {
  filter: drop-shadow(0 0 10px var(--tgm-theme-text-color-semi));
}

/* .taskbar .item:hover {
  opacity: 1;
  transition: .6s;
} */

.settings {
  flex-grow: 1;
  padding: 10px 0;
  margin: 10px;
  cursor: pointer;
  opacity: 0.85;
}

.settings.off svg {
  stroke: var(--tgm-theme-text-color);
  fill: #f5f5f580;
}

.settings svg {
  height: 24px;
  width: 24px;
  fill: var(--tgm-theme-text-color);
  filter: drop-shadow(0 0 5px #000);
}

.blessings {
  font-family: 'Oswald';
  background: var(--tgm-theme-bg-color);
  opacity: 0.85;
  border-radius: 6px;
  margin: 12px;
  padding: 10px;
  font-size: 1.25rem;
}

.blessings-faith {
    color: var(--tgm-theme-text-color) !important;
    min-width: 100px;
    color: rgb(28, 181, 28);
}

.blessings svg {
  fill: transparent;
  stroke: var(--tgm-theme-text-color);
  filter: none;
}

.faith-char {
  font-weight: 500;
  height: 18px !important;
}

.pope {
  position: absolute;
  z-index: 10000;
}

.pope.game-ver1 {
  top: calc(var(--dvh) - 360px);
  left: calc(50vw - 70px);
  width: 160px;
  transition: opacity .75s;
  /* animation: pope_arrival2 9s ease-out 0s; */
}

.pope.game-ver1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* animation: pope_arrival2 8s ease-in-out 0s; */
}

.ton-waves {
  position: absolute;
  background: url("images/ton-overlay2.png") no-repeat;
  width: 130px;
  height: 140px;
  top: calc(var(--dvh) - 557px);
  left: calc(50vw - 65px);
  mix-blend-mode: soft-light;
  animation: waves-ton 3s linear 0s infinite;
}

@keyframes waves-ton {
  0% {opacity: 0.1}
  25% {opacity: 0.75;}
  35% {opacity: 0.3;}
  55% {opacity: 0.85;}
  100% {opacity: 0.1;}

}

/* .relics {
  position: absolute;
  top: calc(var(--dvh) - 420px);
  left: calc(50vw - 80px);
  width: 160px;
  height: 80px;
  cursor: pointer;
}

.relics img:first-child {
  position: absolute;
  top: 14px;
  left: 5px;
  width: 39px;
} */

.fire {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: -9999;
  bottom: 0;
  background: radial-gradient(circle, rgba(0,0,0,0) 70%, rgb(183, 242, 237) 100%) transparent;
  animation: burn 6.5s linear infinite alternate;
}

.fire2 {
  position: absolute;

}

@keyframes burn {
  from {opacity: 0.3; }
  45%  {opacity: 0.18; }
  70%  {opacity: 0.1; }
  to   {opacity: 0.14; }
}

.followers {
  clear: both;
  /* height: calc(100dvh - 340px); */
  flex: 1;
  overflow-y: scroll;
}

.followers .follower-row {
  border-bottom: 1px var(--tgm-theme-secondary-bg-color) dotted;
  padding: 7px 0;
  width: 97%;
}

.followers .follower-row svg {
  fill: white;
  stroke: var(--tgm-theme-text-color);
  filter: none;
}


.followers .follower-row:last-child {
  border-bottom-width: 0;
}


.followers .task {
  width: 34px;
  min-width: 34px;
  height: 18px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 4px 7px;

  background: linear-gradient(180deg, rgb(16, 116, 179) 0%, rgba(0, 0, 0, 0) 100%);
  background-color: rgb(16, 116, 179);
  border-radius: 6px;
  /* border: 1px solid rgb(14, 101, 155); */

  /* box-shadow: 0 0 5px rgba(16, 116, 179, 20); */
  cursor: pointer;
transition: background-color .4s;
}

.followers .task:hover {
  background-color: rgb(15, 92, 140);
  transition: background-color .4s;
}

.followers .task.disabled,
.followers .task.disabled:hover {
  background: #777 !important;
  transition: background-color .4s;
}

.followers .task-claimed svg {
  fill: var(--tgm-theme-text-color);
  stroke: var(--tgm-theme-text-color);
}

.followers .task.claimable {
  background: linear-gradient(180deg, rgb(24, 122, 6) 0%, rgba(0, 0, 0, 0) 100%);
  background-color: rgb(24, 122, 6);
  transition: background-color .4s;
}

.followers .task.claimable:hover {
  background-color: rgb(17, 88, 4);
  transition: background-color .4s;
}


/* HTML: <div class="loader"></div> */
.btn.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:24px auto 24px;
  position: relative;
  color: var(--tgm-theme-hint-color);
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}

.line.loader {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  border: 6px solid;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.page.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: calc(var(--dvh) - 60vh);
  left: calc(50vw - 24px);
  border: 10px solid;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle {
  min-width: 18px;
  height: 18px;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  padding: 0 2px 0 2px;

  background-color: red;
  border-radius: 30%;
}

.taskbar .item .circle {
  position: absolute;
  right: 30px;
  top: 7px;
}

.popover__title {
  text-decoration: none;
  color: var(--tgm-theme-text-color);
  text-align: center;
  padding: 0;
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}

.popover__content {
  margin-top: 2rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -250px;
  transform: translate(0, 10px);
  background-color: var(--tgm-theme-secondary-bg-color);
  padding: .5rem .75rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
  width: auto;
  border-radius: 5px;
}
.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: 0px;
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--tgm-theme-secondary-bg-color) transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__content.left {
  left: -80px;
  width: 250px;
}
.popover__content.left:before {
  right: 175px;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}

.boosts-block {
  position: absolute;
  top: calc(var(--dvh) - 215px);
  left: 10px;
  width: 120px;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.boost-item {
  font-size: smaller;
  color: brown;
}

.boost-item svg {
  fill: brown;
}

.boost-item.inactive {
  color: #333;
}

.boost-item.inactive svg {
  fill: #333;
}

.ton-plate {
  position: absolute;
  top: calc(var(--dvh) - 420px);
  left: calc(50vw - 168px);
  width: 40px;
  height: 40px;
}

.ton-plate img {
  position: absolute;
  top: 0;
  left: 0;
}

.ton-plate .ton-rate {
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-family: 'Open Sans';
  font-size: 10px;
  color: #cfc2b7c3 !important;
  z-index: 10;
  font-weight: 550;
  text-shadow: 0 0px 1px #aaa7a491;
}

.pulpit {
  position: absolute;
  overflow: hidden;
  top: calc(var(--dvh) - 340px);
  left: 60vw;
  height: 170px;
  width: 40vw;
  max-width: 40vw;
}

.pulpit .priest {
  position: absolute;
  width: 80px;
  height: 80px;
  transform: translateX(120%); /* Start off-screen to the right */
  animation: arrive 1.5s ease-out forwards;
}

.pulpit .priest img {
  width: 100%;
}

@keyframes arrive {
  0% {
    transform: translateX(100%);
  }
  20% {
    transform: translateX(80%);
  }
  40% {
    transform: translateX(60%);
  }
  60% {
    transform: translateX(40%);
  }
  80% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.pulpit .priest-ghost {
  left: 60px;
  bottom: 40px;
  z-index: 10;
}

.pulpit .priest-1 {
  left: 0px;
  bottom: 40px;
  z-index: 10;
  animation-delay: 0.1s;
}

.pulpit .priest-2 {
  left: 30px;
  bottom: 40px;
  z-index: 10;
  animation-delay: 0.2s;
}

.pulpit .priest-3 {
  left: 60px;
  bottom: 40px;
  z-index: 10;
  animation-delay: 0.3s;
}

.pulpit .priest-4 {
  left: 90px;
  bottom: 40px;
  z-index: 10;
  animation-delay: 0.4s;
}

.pulpit .priest-5 {
  left: 20px;
  bottom: 80px;
  animation-delay: 0.5s;
}

.pulpit .priest-6 {
  left: 50px;
  bottom: 80px;
  animation-delay: 0.6s;
}

.pulpit .priest-7 {
  left: 80px;
  bottom: 80px;
  animation-delay: 0.7s;
}

.traffy-custom {
    --traffy-taskElementCont-bg: #F7C5FF;
}

.aux-slot {
  position: absolute;
  z-index: 1;
  top: calc(var(--dvh) - 360px);
  left: calc(50vw - 180px);
  width: 90px;
}

.aux-slot img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}